import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import { ContentWrapper } from '@/components/ContentWrapper';

const NotFound: React.FC<PageProps> = () => (
  <Layout>
    <ContentWrapper>Coming soon</ContentWrapper>
  </Layout>
);

export default NotFound;
